import * as React from "react"
import Layout from "../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import ImageDownload from "../../components/ImageDownload"

const IndexPage = () => {
    const root = "/img/download/values"

    return (
        <Layout pageTitle="Language and Values">
            <Row>
                <Col>
                    <Link to="../">Back</Link>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={8}>
                    <h3 className="mt-3">Language</h3>
                    <p className="para">
                        The language we use is very important as it imparts
                        Women's Business Club culture, vision and identity.
                    </p>
                    <p className="para">
                        Our language needs to reflect our authenticity,
                        character and values as a company.
                    </p>
                    <p className="para">
                        Our language is <strong>BOLD</strong>, and full of
                        vision, positivity and encouragement. We are full of
                        action - what are we going to do about it? We're not
                        afraid to take risks in what we have to say, but we are
                        still filled with integrity and a heart to see those
                        around us succeed. We put a positive spin on what we see
                        - we're here to be the solution, not just to complain -
                        this is reflected in our language.
                    </p>

                    <h3>Values</h3>
                    <p className="para">
                        Our values shape who we are as a company, and as people.
                        Our values will also shape our target audience - where
                        our values align is where we will work well together and
                        reach the full potential of who we are and what we can
                        do.
                    </p>
                    <p className="para">
                        Let's talk about our values at every opportunity!
                    </p>
                    {/*<p className="para mt-3">
                        Download the full Brand Message document{" "}
                        <a href="https://docs.google.com/document/d/1N17wids4-a-HimzOmu_KfNRug_ea1WvzupO_9SvW3Uk/edit?usp=sharing">
                            here
                        </a>
                        .
    </p> */}

<a
href="https://www.canva.com/design/DAFlPb-2dGg/RqorM5ZRW1zfA0dA6Upfqw/view?utm_content=DAFlPb-2dGg&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
className="btn btn-primary mt-3 mr-3"
target="_blank"
>
Download Square Images
</a>
<a
href="https://www.canva.com/design/DAFlPb-2dGg/RqorM5ZRW1zfA0dA6Upfqw/view?utm_content=DAFlPb-2dGg&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
className="btn btn-primary mt-3"
target="_blank"
>
Download Horizontal Images
</a>

                </Col>
            </Row>
            
            <ImageDownload
                root={root}
                name="Courageous Leadership"
                filename="courageous-leadership"
                text="We are change-makers, mould breakers, ceiling shatterers and big thinkers with a desire to bring change to the world around us. We are risk takers and not afraid to challenge the norms and try something new. We are pioneers & innovators, changing the course of history."
                squareHref=""
                horizontalHref=""
            />
            <ImageDownload
                root={root}
                hasSquare
                name="Disruptive Generosity"
                filename="disruptive-generosity"
                text="We are committed to using our success and our resources to not only disrupt the industries we are in, but even more importantly the world around us. Successful women can tackle things like climate change, systemic racism, poverty, human trafficking and illiteracy. We believe in using generosity to empower the disempowered."
                squareHref=""
                horizontalHref=""
           />
            <ImageDownload
                root={root}
                hasSquare
                name="Intentional Kindness"
                filename="intentional-kindness"
                text="We value kindness and compassion above all else, and will never stand for any kind of bullying, or disunity in anything we do. We go out of our way to encourage and support those around us with sincerity, choosing to be a life-giving voice."
                squareHref=""
                horizontalHref=""
            />
            <ImageDownload
                root={root}
                hasSquare
                name="Powerful Community"
                filename="powerful-community"
                text="We believe in the power of creating intentional community and strategic support systems with women who pull out our potential in ways we can’t do on our own and challenge us to always be better."
                squareHref=""
                horizontalHref=""
            />
            <ImageDownload
                root={root}
                hasSquare
                name="Strategic Collaboration"
                filename="strategic-collaboration"
                text="We encourage you to leverage the knowledge and expertise in the room! With powerful community comes a willingness to share and collaborate, truly investing in each other."
                squareHref=""
                horizontalHref=""
            />


        </Layout>
    )
}

export default IndexPage
